import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google"
import logoImg from "../components/images/logo-symbol-white.png"
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";

const StyledTitle = styled('div') `
    margin-top: 1em;
    font-size: 5em;
    font-weight: 2em;
`
const StyledSubTitle = styled('div') `
    margin-top: 0.5em;
    font-size: 1.5em;
    font-weight: 1em;
`

const StyledLogo = styled('img') `
    width: 10%;
    height:10%;
    margin-top: 3em;
`

const StyledButton = styled('button') `
	margin: 0 auto !immportant;
	background-color: #ffffff;
	color: #001934;
	margin-top: 2em;
	width: fit-content !important;
	height: 2em;
	border-radius: 2em;
	font-size: 1.5em;
	cursor: pointer;
`
const Login = ({handleLogin}) => {
    return (     
        <main>
            <Helmet>
                <style>{'body { background-color: #001934; text-align: center; color: #ffffff }'}</style>
            </Helmet>
            <StyledLogo src={logoImg} alt={'logo'} />
            <StyledTitle>
                Welcome to Arham Labs!
            </StyledTitle>
            <StyledSubTitle>
                Please login using you Arham Labs Credentials to continue.
            </StyledSubTitle>
            			<div
				style={{
					marginTop: "20px",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<GoogleOAuthProvider clientId="801286342355-vjkik2o56lr9aibb2ns7mrcobh3n0stb.apps.googleusercontent.com">
					<GoogleLogin
						size="large"
						theme="outline"
						shape="pill"
						width="180"
						logo_alignment="center"
						onSuccess={handleLogin}
						onFailure={(err) => {
							console.log("sso", err)
						}}
						scope="https://www.googleapis.com/auth/directory.readonly"
					/>
				</GoogleOAuthProvider>
			</div>
      </main>
      )
  }
  export default Login