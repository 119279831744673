import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';

import { Layout, Link } from '$components';
import NextPrevious from '../components/NextPrevious';
import config from '../../config';
import { Edit, StyledHeading, StyledMainWrapper } from '../components/styles/Docs';
import Login from '../components/Login';

const forcedNavOrder = config.sidebar.forcedNavOrder;

export default class MDXRuntimeTest extends Component {
  constructor(props){
    super(props);
    this.state = { isLoggedIn: typeof window !== "undefined" && window.localStorage.getItem('isLoggedIn') || false }
    this.handleLogin = this.handleLogin.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogin (response) {
    let validEmails, people, userRole;
    const apiKey = 'AIzaSyD2cAj_27-h0m2RYq6ZVZDwaKLfEc3zCag';
    typeof window !== "undefined" && localStorage.setItem('role', 'Admin')
    typeof window !== "undefined" && localStorage.setItem('isLoggedIn',"true")
    this.setState({ isLoggedIn: true })
    // fetch('https://content-people.googleapis.com/v1/people:listDirectoryPeople?' + 
    //   new URLSearchParams({
    //   sources: 'DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE',
    //   readMask: 'emailAddresses,organizations',
    //   key: apiKey
    //   }), 
    //   {
    //     headers: new Headers({
    //       'authorization': `Bearer ${response.tokenObj['access_token']}`,
    //       'content-type': 'application/json',
    //       'accept': 'application/json'
    //     })
    //   }
    // )
    // .then((res) => res.json())
    // .then((data)=> {
    //   people =  data.people
    //   validEmails = data.people.map((user)=>{
    //     return user.emailAddresses[0].value;
    //   })
    //   if(validEmails.indexOf(response.profileObj.email)>-1) {
    //     localStorage.setItem('isLoggedIn',"true")
    //     userRole = people.find((user)=>{
    //       userRole = user.emailAddresses.find((email)=>{
    //         return email.value===response.profileObj.email
    //       })
    //       return userRole
    //     })
    //     localStorage.setItem('role', userRole.organizations[0].department)
    //     this.setState({ isLoggedIn: true })
    //   }
    // }) 
  }

  handleLogout () {
    this.setState({ isLoggedIn: false })
    localStorage.clear();
  }
  render() {
    const { data } = this.props;

    if (!data) {
      return this.props.children;
    }
    const {
      allMdx,
      mdx,
      site: {
        siteMetadata: { docsLocation, title },
      },
    } = data;

    const githubIcon = require('../components/images/github.svg').default;
    const navItems = allMdx.edges
      .map(({ node }) => node.fields.slug)
      .filter(slug => slug !== '/')
      .sort()
      .reduce(
        (acc, cur) => {
          if (forcedNavOrder.find(url => url === cur)) {
            return { ...acc, [cur]: [cur] };
          }

          let prefix = cur.split('/')[1];

          if (config.gatsby && config.gatsby.trailingSlash) {
            prefix = prefix + '/';
          }

          if (prefix && forcedNavOrder.find(url => url === `/${prefix}`)) {
            return { ...acc, [`/${prefix}`]: [...acc[`/${prefix}`], cur] };
          } else {
            return { ...acc, items: [...acc.items, cur] };
          }
        },
        { items: [] }
      );

    const nav = forcedNavOrder
      .reduce((acc, cur) => {
        return acc.concat(navItems[cur]);
      }, [])
      .concat(navItems.items)
      .map(slug => {
        if (slug) {
          const { node } = allMdx.edges.find(({ node }) => node.fields.slug === slug);

          return { title: node.fields.title, url: node.fields.slug };
        }
      });

    // meta tags
    const metaTitle = mdx.frontmatter.metaTitle;

    const metaDescription = mdx.frontmatter.metaDescription;

    let canonicalUrl = config.gatsby.siteUrl;

    canonicalUrl =
      config.gatsby.pathPrefix !== '/' ? canonicalUrl + config.gatsby.pathPrefix : canonicalUrl;
    canonicalUrl = canonicalUrl + mdx.fields.slug;

    return (
      this.state.isLoggedIn? 
      <Layout {...this.props} handleLogout={this.handleLogout} >
        <Helmet>
          {metaTitle ? <title>{metaTitle}</title> : null}
          {metaTitle ? <meta name="title" content={metaTitle} /> : null}
          {metaDescription ? <meta name="description" content={metaDescription} /> : null}
          {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
          {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
          {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
          {metaDescription ? (
            <meta property="twitter:description" content={metaDescription} />
          ) : null}
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <div className={'titleWrapper'}>
          <StyledHeading>{mdx.fields.title}</StyledHeading>
          <Edit className={'mobileView'}>
            {docsLocation && (
              <Link className={'gitBtn'} to={`${docsLocation}/${mdx.parent.relativePath}`}>
                <img src={githubIcon} alt={'Github logo'} /> Edit on GitHub
              </Link>
            )}
          </Edit>
        </div>
        <StyledMainWrapper>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </StyledMainWrapper>
        <div className={'addPaddTopBottom'}>
          <NextPrevious mdx={mdx} nav={nav} />
        </div>
      </Layout>:<Login isLoggedIn = {this.state.isLoggedIn} handleLogin={this.handleLogin}/>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        metaTitle
        metaDescription
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`;